<template>
	<v-main class="pa-0">
		<v-card elevation="1">
			<v-card-title
				class="text-subtitle-1 font-weight-bold"
				:class="$vuetify.theme.dark ? 'secondary darken-1' : 'grey lighten-3 grey--text text--darken-1'"
			>
				<v-icon small left :class="$vuetify.theme.dark ? 'secondary darken-1' : ' grey--text text--darken-1'">
					far fa-message
				</v-icon>
				Mesajlar
				<v-spacer></v-spacer>
			</v-card-title>
			<v-card-text class="pa-0">
				<v-dialog max-width="600" v-model="messageDialog" v-if="selectedMessage">
					<v-card tile>
						<v-card-title> Mesaj </v-card-title>
						<v-card-text>
							{{ selectedMessage.message }}
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								dark
								color="green darken-1"
								small
								@click="changeStatus(selectedMessage)"
								v-if="selectedMessage.status.toLowerCase() === 'yeni'"
							>
								Okundu olarak işaretle
							</v-btn>
							<v-btn dark color="red darken-1" small @click="closeMessageDialog"> Kapat </v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>

				<v-data-table
					:headers="filteredHeaders"
					:items="grid.data"
					:options.sync="options"
					:server-items-length="grid.count"
					:loading="grid.loading"
					:footer-props="footerProps"
				>
					<template v-slot:[`item.date`]="{ item }">
						{{ $moment.utc(item.date).local().format('DD-MM-YYYY HH:mm') }}
					</template>
					<template v-slot:[`item.listingKey`]="{ item }">
						<v-btn text small link @click.stop="openCar(item)">
							{{ item.listingKey }}
						</v-btn>
					</template>

					<template v-slot:[`item.status`]="{ item }">
						<v-chip small :color="setStatusColor(item)" dark>{{ item.status }}</v-chip>
					</template>
					<template v-slot:[`item.actions`]="{ item }">
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-btn icon @click="show(item)" v-bind="attrs" v-on="on">
									<v-icon small>fa-magnifying-glass</v-icon>
								</v-btn>
							</template>
							<span>Mesajı oku</span>
						</v-tooltip>
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-btn icon @click="changeStatus(item)" v-bind="attrs" v-on="on">
									<v-icon small>
										{{ item.status.toLowerCase() === 'yeni' ? 'fa-envelope' : 'fa-envelope-open' }}
									</v-icon>
								</v-btn>
							</template>
							<span
								v-text="item.status.toLowerCase() === 'yeni' ? 'Okundu olarak işaretle' : 'Okunmadı olarak işaretle'"
							></span>
						</v-tooltip>

						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-btn icon @click="deleteItem(item)" v-bind="attrs" v-on="on">
									<v-icon x-small> fa-trash </v-icon>
								</v-btn>
							</template>
							<span>Mesajı sil</span>
						</v-tooltip>
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>
	</v-main>
</template>

<script>
import {
	CHANGE_CONTACT_MESSAGE_STATUS,
	DELETE_CONTACT_MESSAGE,
	FETCH_MESSAGE_GRID_DATA,
} from '@/store/modules/listingContact.module';
import { DISPOSE_MESSAGE, SET_MESSAGE_DIALOG } from '@/store/modules/messageDialog.module';
import carListMixin from '@/views/listing/pages/cars/_mixins/carListMixin';
import { mapActions, mapGetters } from 'vuex';

export default {
	name: 'ListingContactMessages',
	mixins: [carListMixin],
	data() {
		return {
			options: {
				itemsPerPage: 50,
			},
			headers: [
				{ text: 'Tarih', value: 'date' },
				{ text: 'Araç Id', value: 'listingKey' },
				{ text: 'Adı Soyadı', value: 'name' },
				{ text: 'Telefon', value: 'phone' },
				{ text: 'E-Posta', value: 'email' },
				{ text: 'Durumu', value: 'status', align: 'center' },
				{ text: 'İşlemler', value: 'actions', sortable: false, align: 'center' },
			],
			footerProps: {
				'items-per-page-options': [5, 10, 25, 50, 100, 500],
			},
			messageDialog: false,
			selectedMessage: null,
		};
	},
	computed: {
		...mapGetters({
			grid: 'messageGrid',
		}),
		filteredHeaders() {
			return this.headers;
		},
	},
	methods: {
		...mapActions({
			setMessageDialog: SET_MESSAGE_DIALOG,
			disposeMessageDialog: DISPOSE_MESSAGE,
			fetchGridData: FETCH_MESSAGE_GRID_DATA,
			deleteContactMessage: DELETE_CONTACT_MESSAGE,
			changeContactMessageStatus: CHANGE_CONTACT_MESSAGE_STATUS,
		}),
		setStatusColor(item) {
			const status = item.status.toLowerCase();
			if (status === 'yeni') return 'green';
			if (status === 'cevaplandı') return 'red';
			return 'secondary';
		},
		deleteItem(item) {
			this.setMessageDialog({
				messageType: 'warning',
				subTitle: 'Emin misiniz?',
				text: 'Bu kaydı silmek istediğinizden emin misiniz?',
				closeButtonText: 'Vazgeç',
				buttons: [
					{
						text: 'Evet',
						color: 'teal',
						action: () => this.delete(item),
					},
				],
			});
		},
		delete(item) {
			this.deleteContactMessage(item).then(() => {
				this.disposeMessageDialog();
			});
		},
		changeStatus(item) {
			const statusText = item.status.toLowerCase() === 'yeni' ? 'okundu' : 'okunmadı';
			this.setMessageDialog({
				messageType: 'warning',
				subTitle: 'Emin misiniz?',
				text: `Bu mesajın durumunu <span class='text-subtitle-1 font-weight-bold teal--text'>${statusText}</span> olarak güncellemek istediğinizden emin misiniz?`,
				closeButtonText: 'Vazgeç',
				buttons: [
					{
						text: 'Evet',
						color: 'teal',
						action: () => this.status(item),
					},
				],
			});
		},
		status(item) {
			this.changeContactMessageStatus(item).then(() => {
				this.disposeMessageDialog();
				this.closeMessageDialog();
			});
		},
		show(item) {
			this.selectedMessage = item;
			this.messageDialog = true;
		},
		closeMessageDialog() {
			this.selectedMessage = null;
			this.messageDialog = false;
		},
		openCar(item) {
			const car = { id: item.listingKey, url: item.url };
			const route = this.$router.resolve({ name: 'carDetails', params: this.createURLParams(car) });
			window.open(route.href, '_blank');
		},
	},
	watch: {
		options: {
			handler() {
				const payload = {
					orderByField: this.options.sortBy.length === 0 ? '' : this.options.sortBy[0],
					orderByDesc: this.options.sortDesc.length === 0 ? true : this.options.sortDesc[0],
					pageSize: this.options.itemsPerPage,
					pageNumber: this.options.page,
				};

				this.fetchGridData(payload);
			},
		},
	},
};
</script>

<style></style>
